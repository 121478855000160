<template>
  <v-layout
    row
    justify-center
    v-if="languages"
    class="translate-control"
  >
    <!-- INI MODAL -->
    <v-dialog
      v-if="data"
      v-model="dialog"
      persistent
      max-width="500"
    >
      <!-- <template v-slot:activator="{ on }"> -->
        <template v-slot:activator="{ }">
        <v-text-field
          v-if="isText"
          v-bind="{ 'outlined' : true}"
          v-model="data[locale]"
          :label="labelName.concat(showInfoEdited ? ` [${$t('common.infoEditedTranslate', locale)}]` : '')"
          :append-icon="languages.length > 1 ? 'mdi-routes' : null"
          @input="(...args) => { onChange('blur', ...args) }"
          @click:append="onOpenTranslate"
          hide-details
          style="padding: 0 2px;margin: 11px 0;"
          :prepend-icon="dtouchDeeplTranslateActive ? 'mdi-translate' : null"
          @click:prepend="handleDeeplTranslate"
          :loading="loading"
        />
        <div
          v-else-if="isRichText"
          style="display: inline-block;width:100%;padding: 0 2px;margin: 11px 0;"
        >
          <table cellpadding="0" cellspacing="0">
            <tr 
              v-if="dtouchDeeplTranslateActive && showInfoEdited"
            >
              <td colspan="2">
                {{` [${$t('common.infoEditedTranslate', locale)}]` }}
              </td>
            </tr>
            <tr>
              <td style="width:100%;">
                <vue-editor
                  v-model="data[locale]"
                  :editor-toolbar="customToolbar"
                  style="z-index: 3000;border-radius: 5px;"
                  :style="{ border: `${borderSize}px solid ${editorBorderColor} !important`, padding: `${borderMargin}px` }"
                  @input="(...args) => { handleChangeRichTranslate(locale) }"
                  @focus="handleEditorChangeFocus(true)"
                  @blur="handleEditorChangeFocus(false)"
                />
              </td>
              <td style="vertical-align: top;">
                <v-icon
                  @click="onOpenTranslate"
                  style="margin-top: 15px;margin-left:-35px"
                  :style="{ color: `${editorBorderColor} !important` }"
                >
                  {{ languages.length > 1 ? 'mdi-routes' : null }}
                </v-icon>
                <v-icon
                  style="margin-top: 15px;margin-left:-45px"
                  v-if="dtouchDeeplTranslateActive"
                  @click="handleDeeplTranslate"
                >
                  mdi-translate
                </v-icon>
              </td>
            </tr>
          </table>
        </div>
        <v-textarea
          v-else-if="isTextArea"
          v-model="data[locale]"
          :label="labelName.concat(showInfoEdited ? ` [${$t('common.infoEditedTranslate', locale)}]` : '')"
          name="input-7-1"
          filled
          auto-grow
          :append-icon="languages.length > 1 ? 'mdi-routes' : null"
          @input="(...args) => { handleCloseTranslate() }"
          @click:append="onOpenTranslate"
          style="padding: 0 2px;margin: 11px 0;"
          :prepend-icon="dtouchDeeplTranslateActive ? 'mdi-translate' : null"
          @click:prepend="handleDeeplTranslate"
          :loading="loading"
        />
        <v-layout
          v-else-if="isFile"
          wrap
        >
          <custom-file-input
            v-model="data[locale]"
            :languages="languages"
            :onOpenTranslate="onOpenTranslate"
            @input="(...args) => { handleCloseTranslate() }"
          />
        </v-layout>
        <v-layout
          v-else-if="isImage"
          wrap
          @click="onOpenTranslate"
          style="padding: 10px 20px;"
        >
          <select-image
            :folder="folder"
            :image="getTranslation(data, locale)"
            :locale="locale"
            :imageWidth="imageWidth"
            :imageHeight="imageHeight"
            :aspectRatio="aspectRatio"
            style="width: 100%"
          />
        </v-layout>
        <v-progress-linear
          v-if="isRichText && loading"
          indeterminate
          color="primary"
        />
      </template>
      <v-card>
        <!-- INI TABS -->
        <v-toolbar
          color="primary"
          dark
          tabs
          class="toolbarTabs"
        >
          <template v-slot:extension>
            <v-tabs
              v-model="tabs"
              centered
              slider-color="white"
              background-color="primary"
              dark
            >
              <v-tab
                v-for="item in prepareLanguages(languages)"
                :key="item"
              >
                {{ item.toUpperCase() }}
              </v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tabs">
          <v-tab-item
            v-for="item in prepareLanguages(languages)"
            :key="item"
          >
            <v-card>
              <v-card-text>
                <v-text-field
                  v-if="isText"
                  v-bind="{ 'outlined' : true}"
                  v-model="data[item]"
                  :label="labelName"
                  :key="item.concat('txt')"
                  @input="(...args) => { handleCloseTranslate(item) }"
                  :loading="loading"
                />
                <vue-editor
                  v-else-if="isRichText"
                  v-model="data[item]"
                  :editor-toolbar="customToolbar"
                  @input="(...args) => { handleCloseTranslate(item) }"
                />
                <v-textarea
                  v-else-if="isTextArea"
                  v-model="data[item]"
                  :label="labelName"
                  :key="item.concat('textArea')"
                  name="input-7-1"
                  box
                  auto-grow
                  @input="(...args) => { handleCloseTranslate(item) }"
                  :loading="loading"
                />
                <select-image 
                  v-else-if="isImage"
                  :image="data[item]"
                  :onChange="(v) => handleChangeImage(v, item)"
                  :locale="locale"
                  :imageWidth="imageWidth"
                  :imageHeight="imageHeight"
                  :aspectRatio="aspectRatio"
                  :folder="folder"
                />
                <v-layout
                  v-else-if="isFile"
                  wrap
                >
                  <custom-file-input
                    v-model="data[item]"
                    :languages="languages"
                    :onOpenTranslate="onOpenTranslate"
                    @input="(...args) => { handleCloseTranslate() }"
                  />
                </v-layout>
                <v-progress-linear
                  v-if="isRichText && loading"
                  indeterminate
                  color="primary"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <!-- END TABS -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="handleCloseDialog"
          >
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END MODAL -->
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
//import { VueEditor, Quill } from 'vue2-editor'
import api from '@/services/api'
import utils from '@/services/utils'
import { VueEditor } from 'vue2-editor'
import CustomFileInput from './CustomFileInput'
import SelectImage from '@/components/selectImage/Index'

const fullToolbar = [
  // [{ font: ["arial"] }],
  // [{ font: [] }],
  // [{ font: [{ list: "ordered" }, { list: "bullet" }, { list: "check" }] }],
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  [{ size: ["small", false, "large", "huge"] }],
  // [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }
  ],
  [{ header: 1 }, { header: 2 }],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ color: [] }, { background: [] }],
  ["link",
    // "image",
    // "formula"
  ],
  [{ direction: "rtl" }],
  ["clean"]
]
// var Font = Quill.import("formats/font");
// Font.whitelist = ["roboto"];

export default {
  name: 'translate',
  components: {
    VueEditor,
    CustomFileInput,
    SelectImage,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: Object,
      required: false,
      default: null
    },
    languages: {
      type: Array,
      required: false,
      default: null
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    folder: {
      type: String,
      default: null,
    },
    imageWidth: {
      type: Number,
      default: null,
    },
    imageHeight: {
      type: Number,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
  },
  data () {
    return {
      fileData: null,
      labelName: '',
      data: undefined,
      dialog: false,
      tabs: null,
      customToolbar: fullToolbar,
      borderMargin: '0',
      borderSize: '1',
      editorBorderColor: 'gray',
      dtouchAutoTranslate: true,
      showInfoEdited: false,
      ifRichFirstLoad: true,
      loading: false,
    }
  },
  computed: {
    ...mapState('app',['locale', 'environment', 'dtouchWorkspace', 'dtouchDeeplTranslateActive', 'dtouchDeeplTranslateLocale']),
    FontSizeContent () {
      return this.$store.state.app.FontSizeContent
    },
    isText () {
      return (!this.type || this.type === 'text')
    },
    isRichText () {
      return this.type === 'richText'
    },
    isTextArea () {
      return this.type === 'textArea'
    },
    isFile () {
      return this.type === 'file'
    },
    isImage () {
      return this.type === 'image'
    },
    workspaceID () {
      return this.environment === 'dtouch' ? this.dtouchWorkspace : null
    },
  },
  watch: {
    value () {
      this.prepareData()
      /*
      this.data = JSON.parse(JSON.stringify(v).split('#$#').join("'"))
      if (this.languages && this.languages.length > 1)
      for (let i = 0; i < this.languages.length; i++) {
        if (!this.data[this.languages[i]]) this.data[this.languages[i]] = ''
      }
      */
    },
    label (v) {
      this.labelName = v ? `${v}` : 'common.name'
    },
  },
  mounted () {
    this.prepareData()
    /*
    this.data = JSON.parse(JSON.stringify(this.value).split('#$#').join("'"))

    if (this.languages && this.languages.length > 1)
      for (let i = 0; i < this.languages.length; i++) {
        if (!this.data[this.languages[i]]) this.data[this.languages[i]] = ''
      }
    */
    this.labelName = this.label ? `${this.label}` : 'common.name'
  },
  methods: {
    prepareLanguages (v) {
      if (this.dtouchDeeplTranslateActive && this.dtouchDeeplTranslateLocale) {
        const aux = v.filter(x => x === this.dtouchDeeplTranslateLocale)
        aux.push(...v.filter(x => x !== this.dtouchDeeplTranslateLocale))
        return aux
      } 
      return v
    },
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    prepareData () {
      const aux = this.value ? this.value : {}
      this.data = JSON.parse(JSON.stringify(aux).split('#$#').join("'"))

      if (this.languages && this.languages.length > 1)
        for (let i = 0; i < this.languages.length; i++) {
          if (!this.data[this.languages[i]]) this.data[this.languages[i]] = ''
        }
    },
    handleEditorChangeFocus (v) {
      this.borderMargin = v ? '0' : '1'
      this.borderSize = v ? '2' : '1'
      this.editorBorderColor = v ? this.$vuetify.theme.themes.light.primary : 'gray'
    },
    onOpenTranslate () {
      this.dialog = true
    },
    onChange () {
      if (this.dtouchDeeplTranslateActive) {
        this.showInfoEdited = true
      }
      this.$emit('input', this.data)
    },
    handleChangeRichTranslate (locale) {
      if (!this.ifRichFirstLoad && this.dtouchDeeplTranslateActive && this.dtouchDeeplTranslateLocale === locale) {
        this.showInfoEdited = true
      }
      this.ifRichFirstLoad = false
      this.$emit('input', this.data)
    },
    handleCloseTranslate(locale) {
      if (this.dtouchDeeplTranslateActive && this.dtouchDeeplTranslateLocale === locale) {
        this.showInfoEdited = true
      }
      this.$emit('input', this.data)
    },
    handleChangeImage (v, locale) {
      const body = {
        base64: v.base64, 
        folder: v.folder, 
        name: null,
        ext: v.ext,
        environment: null,
      }
      api.addItem('dtouch', 'v1/private/utils/media', body)
        .then(response => {
          if (response.status === 200) {
            this.data[locale] = response.data.url
            this.onChange()
          }
        })
        .catch(error => {
          console.log('error', error)
        })
    },
    async handleCloseDialog () {
      await this.handleDeeplTranslate()
      this.handleCloseTranslate ()
      this.dialog = false 
    },
    async handleDeeplTranslate () {
      if (!this.workspaceID) return
      this.loading = true
      try {
        if (this.dtouchDeeplTranslateActive && this.showInfoEdited) {
          const data = await new Promise((resolve, reject) => api.put('dtouch', `v1/private/workspaces/${this.workspaceID}/deepl-translate`, this.data)
            .then(response => {
              if (response && response.data) resolve(response.data)
              else resolve(null)
            })
            .catch(error => {
              reject(error)
            }))
          if (data) this.data= data
          this.$emit('input', this.data)
        }
        this.showInfoEdited = false
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
  }
}
</script>
<style>
  .ql-snow.ql-toolbar {
    left: 0px !important;
    padding-top: 13px !important;
    padding-right: 23px !important;
    border: 0 !important;
  }
  #quill-container {
    border: 0;
    border-top: 1px solid lightgray !important;
  }
  .quillWrapper:focus {
    border-color: var(--v-primary-base) !important;
  }
  .toolbarTabs .v-toolbar__content {
    height: 0px !important;
  }
  /* .ql-font span[data-value="arial"]::before {
    font-family: "Arial";
    content: "Arial" !important;
  } */
</style>

