<template>
  <div style="width:100%;">
    <v-text-field
      v-model="url"
      label="PDF"
      hide-details
      style="margin-left:15px;width:95%;"
      prepend-icon="mdi-paperclip"
      :append-outer-icon="onOpenTranslate && languages.length > 1 ? 'mdi-routes' : null"
      @input="(...args) => { handleChangeIsModified () }"
      @click:prepend="handleOpenFileInput"
      @click:append-outer="onOpenTranslate"
    />
    <input
      type="file"
      class="my-custom-file-input"
      ref="customFileInput"
      @input="handleChange"
    >
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  name: 'CustomFileInput',
  components: {
  },
  props: {
    value: {
      type: String,
      required: true,
      default: null
    },
    onOpenTranslate: {
      type: Function,
      required: false,
      default: null
    },
    languages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    url: null,
  }),
  computed: {
    locale () {
      return this.$store.state.app.locale
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
  },
  watch: {
    value (v) {
      this.url = v
    },
  },
  mounted () {
    this.url = this.value
  },
  methods: {
    handleChangeIsModified () {
      this.$emit('input', this.url)
    },
    handleOpenFileInput () {
      this.$refs.customFileInput.click()
    },
    handleChange () {
      this.handleChangePDFFile(this.$refs.customFileInput.files[0])
    },
    handleChangePDFFile (value) {
      const that = this
      this.loading = true
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onload = function () {
        that.uploadPDF(reader.result)
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
        this.loading = false
      }
    },
    onChange () {
      this.$emit('input', this.data)
    },
    uploadPDF (file) {
      /*const body = {
        newPdf: file,
        EnterpriseID: this.enterpriseID
      }
      */
      const body = {
        base64: file, 
        folder: this.workspaceID, 
        name: null,
        ext: 'pdf',
        environment: null,
      }
      api.addItem('dtouch', 'v1/private/utils/media', body)
        .then(response => {
          if (response.status === 200) {
            this.url = response.data.url
            this.handleChangeIsModified()
            this.loading = false
          }
        })
        .catch(error => {
          console.log('error', error)
          this.loading = false
        })
    },
  }
}
</script>
<style>
  .my-custom-file-input {
    visibility: hidden !important;
  }
</style>
