var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.languages)?_c('v-layout',{staticClass:"translate-control",attrs:{"row":"","justify-center":""}},[(_vm.data)?_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [(_vm.isText)?_c('v-text-field',_vm._b({staticStyle:{"padding":"0 2px","margin":"11px 0"},attrs:{"label":_vm.labelName.concat(_vm.showInfoEdited ? (" [" + (_vm.$t('common.infoEditedTranslate', _vm.locale)) + "]") : ''),"append-icon":_vm.languages.length > 1 ? 'mdi-routes' : null,"hide-details":"","prepend-icon":_vm.dtouchDeeplTranslateActive ? 'mdi-translate' : null,"loading":_vm.loading},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.onChange.apply(void 0, [ 'blur' ].concat( args )) },"click:append":_vm.onOpenTranslate,"click:prepend":_vm.handleDeeplTranslate},model:{value:(_vm.data[_vm.locale]),callback:function ($$v) {_vm.$set(_vm.data, _vm.locale, $$v)},expression:"data[locale]"}},'v-text-field',{ 'outlined' : true},false)):(_vm.isRichText)?_c('div',{staticStyle:{"display":"inline-block","width":"100%","padding":"0 2px","margin":"11px 0"}},[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"}},[(_vm.dtouchDeeplTranslateActive && _vm.showInfoEdited)?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s((" [" + (_vm.$t('common.infoEditedTranslate', _vm.locale)) + "]"))+" ")])]):_vm._e(),_c('tr',[_c('td',{staticStyle:{"width":"100%"}},[_c('vue-editor',{staticStyle:{"z-index":"3000","border-radius":"5px"},style:({ border: (_vm.borderSize + "px solid " + _vm.editorBorderColor + " !important"), padding: (_vm.borderMargin + "px") }),attrs:{"editor-toolbar":_vm.customToolbar},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleChangeRichTranslate(_vm.locale) },"focus":function($event){return _vm.handleEditorChangeFocus(true)},"blur":function($event){return _vm.handleEditorChangeFocus(false)}},model:{value:(_vm.data[_vm.locale]),callback:function ($$v) {_vm.$set(_vm.data, _vm.locale, $$v)},expression:"data[locale]"}})],1),_c('td',{staticStyle:{"vertical-align":"top"}},[_c('v-icon',{staticStyle:{"margin-top":"15px","margin-left":"-35px"},style:({ color: (_vm.editorBorderColor + " !important") }),on:{"click":_vm.onOpenTranslate}},[_vm._v(" "+_vm._s(_vm.languages.length > 1 ? 'mdi-routes' : null)+" ")]),(_vm.dtouchDeeplTranslateActive)?_c('v-icon',{staticStyle:{"margin-top":"15px","margin-left":"-45px"},on:{"click":_vm.handleDeeplTranslate}},[_vm._v(" mdi-translate ")]):_vm._e()],1)])])]):(_vm.isTextArea)?_c('v-textarea',{staticStyle:{"padding":"0 2px","margin":"11px 0"},attrs:{"label":_vm.labelName.concat(_vm.showInfoEdited ? (" [" + (_vm.$t('common.infoEditedTranslate', _vm.locale)) + "]") : ''),"name":"input-7-1","filled":"","auto-grow":"","append-icon":_vm.languages.length > 1 ? 'mdi-routes' : null,"prepend-icon":_vm.dtouchDeeplTranslateActive ? 'mdi-translate' : null,"loading":_vm.loading},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate() },"click:append":_vm.onOpenTranslate,"click:prepend":_vm.handleDeeplTranslate},model:{value:(_vm.data[_vm.locale]),callback:function ($$v) {_vm.$set(_vm.data, _vm.locale, $$v)},expression:"data[locale]"}}):(_vm.isFile)?_c('v-layout',{attrs:{"wrap":""}},[_c('custom-file-input',{attrs:{"languages":_vm.languages,"onOpenTranslate":_vm.onOpenTranslate},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate() }},model:{value:(_vm.data[_vm.locale]),callback:function ($$v) {_vm.$set(_vm.data, _vm.locale, $$v)},expression:"data[locale]"}})],1):(_vm.isImage)?_c('v-layout',{staticStyle:{"padding":"10px 20px"},attrs:{"wrap":""},on:{"click":_vm.onOpenTranslate}},[_c('select-image',{staticStyle:{"width":"100%"},attrs:{"folder":_vm.folder,"image":_vm.getTranslation(_vm.data, _vm.locale),"locale":_vm.locale,"imageWidth":_vm.imageWidth,"imageHeight":_vm.imageHeight,"aspectRatio":_vm.aspectRatio}})],1):_vm._e(),(_vm.isRichText && _vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]}}],null,false,2460503761),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"toolbarTabs",attrs:{"color":"primary","dark":"","tabs":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"centered":"","slider-color":"white","background-color":"primary","dark":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.prepareLanguages(_vm.languages)),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item.toUpperCase())+" ")])}),1)]},proxy:true}],null,false,309862949)}),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.prepareLanguages(_vm.languages)),function(item){return _c('v-tab-item',{key:item},[_c('v-card',[_c('v-card-text',[(_vm.isText)?_c('v-text-field',_vm._b({key:item.concat('txt'),attrs:{"label":_vm.labelName,"loading":_vm.loading},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate(item) }},model:{value:(_vm.data[item]),callback:function ($$v) {_vm.$set(_vm.data, item, $$v)},expression:"data[item]"}},'v-text-field',{ 'outlined' : true},false)):(_vm.isRichText)?_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate(item) }},model:{value:(_vm.data[item]),callback:function ($$v) {_vm.$set(_vm.data, item, $$v)},expression:"data[item]"}}):(_vm.isTextArea)?_c('v-textarea',{key:item.concat('textArea'),attrs:{"label":_vm.labelName,"name":"input-7-1","box":"","auto-grow":"","loading":_vm.loading},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate(item) }},model:{value:(_vm.data[item]),callback:function ($$v) {_vm.$set(_vm.data, item, $$v)},expression:"data[item]"}}):(_vm.isImage)?_c('select-image',{attrs:{"image":_vm.data[item],"onChange":function (v) { return _vm.handleChangeImage(v, item); },"locale":_vm.locale,"imageWidth":_vm.imageWidth,"imageHeight":_vm.imageHeight,"aspectRatio":_vm.aspectRatio,"folder":_vm.folder}}):(_vm.isFile)?_c('v-layout',{attrs:{"wrap":""}},[_c('custom-file-input',{attrs:{"languages":_vm.languages,"onOpenTranslate":_vm.onOpenTranslate},on:{"input":function () {
var args = [], len = arguments.length;
while ( len-- ) args[ len ] = arguments[ len ];
 _vm.handleCloseTranslate() }},model:{value:(_vm.data[item]),callback:function ($$v) {_vm.$set(_vm.data, item, $$v)},expression:"data[item]"}})],1):_vm._e(),(_vm.isRichText && _vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1)],1)],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.handleCloseDialog}},[_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")])],1)],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }